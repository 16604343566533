<template>
  <div>
    <v-card class="ma-3">
      <g-overlay :is-relay="isOverlay"/>
      <page-progress v-bind:is-loading="isLoading"/>
      <page-title icon="mdi-cart-outline" title="Create a new Invoice" :show-button="true" btn-link="/invoices"/>
      <v-container class="pt-0" fluid>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-data-table
                  :headers="orderHeaders"
                  :items="getOrderData"
                  class="elevation-0 mt-0"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col cols="12" sm="8">
                        <g-p-search
                            :loading="isLoading"
                            class="mt-7"
                            @applySearch="handleSearch"
                        />
                      </v-col>
                      <v-col cols="12" sm="4" class="d-flex flex-row-reverse">
                        <v-btn
                            class="mt-8"
                            color="orange darken-2"
                            dark
                            @click="selectAllInvoiceHandler"
                        >
                          <v-icon
                              dark
                              left
                              v-if="isSelectedAll"
                          >
                            mdi-check
                          </v-icon>
                          <v-icon
                              dark
                              left
                              v-else
                          >
                            mdi-minus-circle
                          </v-icon>Select
                        </v-btn>
<!--                        <v-checkbox
                            class="pt-10"
                            v-model="isSelectedAll"
                            label="Select all Invoice"
                            @change="selectAllInvoiceHandler"
                        />-->
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:no-data>
                  Order is empty!
                </template>
                <template v-slot:item.liveLinks={item}>
                  <v-chip
                      class="ma-2"
                      color="green"
                      link
                      text-color="white"
                      @click.prevent.stop="liveLinkHandler(item)"
                  >
                    View Live Link
                  </v-chip>
                </template>
                <template v-slot:item.amount={item}>
                  {{ getAmountWithSymbol(item.amount) }}
                </template>
                <template v-slot:item.publishableAmount={item}>
                  {{ getAmountWithSymbol(item.publishableAmount) }}
                </template>
                <template v-slot:item.discount={item}>
                  {{ getAmountWithSymbol(item.discount) }}
                </template>
                <template v-slot:item.totalAmount={item}>
                  {{ getAmountWithSymbol(item.totalAmount) }}
                </template>
                <template v-slot:item.isPrepayment="{ item }">
                  <g-payment-status-chip v-if="item.isPrepayment" text="Prepaid" color="deep-purple darken-3"/>
                </template>
                <template v-slot:item.actions={item}>
                  <v-simple-checkbox
                      v-ripple="false"
                      color="primary"
                      v-model="item.isInCart"
                      :disabled="item.isInCart"
                      @click="addToCartHandler(item)"
                  />
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12" md="12" sm="12" xl="12" xs="12">
            <v-form ref="cartFormRef" v-model="formValid" lazy-validation>
              <v-card>
                <v-data-table
                    :headers="headers"
                    :items="carts"
                    hide-default-footer
                    :disable-pagination="true"
                >
                  <template v-slot:footer>
                    <v-divider/>
                    <v-toolbar flat>
                      <v-spacer/>
                      <cancel-button @onButtonClick="onCancelInvoice"/>
                      <confirm-button
                          text="Confirm"
                          :disabled="!carts.length"
                          @onButtonClick="onConfirmHandler"
                      />
                    </v-toolbar>
                  </template>
                  <template v-slot:body.append>
                    <tr color="red">
                      <td colspan="4" style="text-align: right">
                        <h3>Sub Total</h3>
                      </td>
                      <td style="text-align: right"><h4>$ {{ subTotal }}</h4></td>
                      <td></td>
                    </tr>
                    <tr color="red">
                      <td colspan="4" style="text-align: right">
                        <h3>Discount</h3>
                      </td>
                      <td style="text-align: right">
                        <v-text-field
                            v-model="invoice.discount"
                            :rules="numberRule"
                            type="number"
                            onclick="this.select()"
                            class="right-align-text discount-field mt-2 mb-1"
                            dense
                            outlined
                        />
                      </td>
                      <td></td>
                    </tr>
                    <tr color="red">
                      <td colspan="4" style="text-align: right">
                        <h3>Payment Service Charge</h3>
                      </td>
                      <td style="text-align: right">
                        <v-text-field
                            v-model="invoice.paymentServiceCharge"
                            :rules="numberRule"
                            type="number"
                            onclick="this.select()"
                            class="right-align-text discount-field mt-2 mb-1"
                            dense
                            outlined
                        />
                      </td>
                      <td></td>
                    </tr>
                    <tr color="red">
                      <td colspan="4" style="text-align: right">
                        <h3>Grand Total</h3>
                      </td>
                      <td style="text-align: right"><h4>$ {{ grandTotal }}</h4></td>
                      <td></td>
                    </tr>
                    <tr color="red">
                      <td colspan="4" style="text-align: right">
                        <h3>Auto Description</h3>
                      </td>
                      <td>
                        <v-icon @click="getAutoInvoiceDescription">mdi-lock-reset</v-icon>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colspan="4" style="text-align: right">
                        <h3>Description</h3>
                      </td>
                      <td>
                        <div class="content-edit-box">
                          <div
                              contenteditable="true"
                              class="contentEditTableBox"
                              ref="editTableBoxDetails"
                              v-html="ediTableContent"
                              @input="handleEditableInput($event)"
                          />
                        </div>
                      </td>
                      <td></td>
                    </tr>
                    <tr color="red">
                      <td colspan="4" style="text-align: right">
                        <h3>Pay Method</h3>
                      </td>
                      <td style="text-align: right">
                        <v-select
                            v-model="invoice.paymentMethod"
                            :items="paymentMethods"
                            :rules="requiredFieldRule"
                            item-text="name"
                            item-value="id"
                            class="discount-field mt-2 mb-1"
                            dense
                            outlined
                        />
                      </td>
                      <td></td>
                    </tr>
                    <tr color="red">
                      <td colspan="4" style="text-align: right">
                        <h3>Send Mail</h3>
                      </td>
                      <td>
                        <v-checkbox
                            v-model="invoice.isSendMail"
                        />
                      </td>
                      <td></td>
                    </tr>
                  </template>
                  <template v-slot:top>
                    <v-toolbar dense flat>
                      Carts
                    </v-toolbar>
                  </template>
                  <template v-slot:no-data>
                    Cart is empty!
                  </template>
                  <template v-slot:item.details={item}>
                    <v-chip
                        class="ma-2"
                        color="green"
                        link
                        text-color="white"
                        @click.prevent.stop="liveLinkHandler(item.details)"
                    >
                      View Live Link
                    </v-chip>
                  </template>
                  <template v-slot:item.total={item}>
                    {{ getAmountWithSymbol(item.total) }}
                  </template>
                  <template v-slot:item.subTotal={item}>
                    {{ getAmountWithSymbol(Number(item.price) * Number(item.quantity)) }}
                  </template>
                  <template v-slot:item.actions={item}>
                    <v-btn
                        color="error"
                        icon
                        @click="confirmToDeleteItem(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
        <counter-link-popup
            :item="currentItem"
            :show="showLiveLink"
            @closePopupHandler="closePopupHandler"
        />
        <confirm-popup
            :show="showItemDeleteConfirm"
            confirm-event="onConfirmDelete"
            @closePopupHandler="showItemDeleteConfirm = false"
            @onConfirmDelete="confirmDelete()"
        >
        <span
        >Are you sure to remove
        <b>{{ this.deleteItem ? this.deleteItem.siteUrl : "" }}</b> from cart?</span
        >
        </confirm-popup>
        <confirm-popup
            :show="showInvoiceConfirm"
            confirm-event="onConfirmOrder"
            @closePopupHandler="showInvoiceConfirm = false"
            @onConfirmOrder="onConfirmHandler(true)"
        >
          <div>
        <span>Are you sure to confirm the invoice amount of
          <b>${{ grandTotal }}, </b>
          from <i>{{ invoice.email && invoice.email ? invoice.email : " " }}</i> ?
       </span>
          </div>
        </confirm-popup>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import PageProgress from "../components/PageProgress";
import PageTitle from "../components/PageTitle";
import AddButton from "../components/buttons/AddButton";
import Validation from "../components/mixins/Validation";
import ConfirmPopup from "../components/popups/ConfirmPopup";
import CancelButton from "../components/buttons/CancelButton";
import ConfirmButton from "../components/buttons/ConfirmButton";
import GPSearch from "../components/GPSearch";
import CounterLinkPopup from "../components/popups/CounterLinkPopup";
import InvoiceMixins from "./mixins/InvoiceMixins";
import GOverlay from "./GOverlay";
import GPaymentStatusChip from "./GPaymentStatusChip";

export default {
  name: "GInvoice",
  components: {
    GPaymentStatusChip,
    GOverlay,
    CounterLinkPopup,
    GPSearch,
    ConfirmButton,
    CancelButton,
    ConfirmPopup,
    AddButton,
    PageTitle,
    PageProgress
  },
  mixins: [Validation, InvoiceMixins]
}
</script>

<style lang="scss">
.price-field .v-input {
  max-width: 200px;
  float: right;
  align-items: center;
}

.discount-field .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 0px;
  display: none;
}
</style>